import React from "react";
import SiteLayout from "../components/SiteLayout";

import ContactForm from "../components/ContactForm/ContactForm";
import CompanyHeadquater from "../components/CompanyHeadquater/";
import GeneralContact from "../components/GeneralContact/";
import Title from "../components/Title/";
import Block from "../components/Block/";
import styles from "../styles/pages.module.sass";
import Seo from "../components/Seo";
import { URLS } from "../constants";

const ThankYouContact = () => (
    <div>
        <Title color={"gold"} mb={20}>
            Díky za váš zájem
        </Title>
        <p>
            V blízké době vás bude kontaktovat s detailní nabídkou vhodnou pro
            vás. Popřípadě se spolu podíváme na jiné možnosti investování do
            zlata.
            <br />
            <br />
            Těšíme se na vás.
        </p>
        <img src={require("../images/icons_new/zlato.svg")} />
    </div>
);

export default class KontaktPage extends React.Component {
    render() {
        return (
            <SiteLayout location={this.props.location}>
                <Seo title={"Kontakt na poradce pro investiční kovy"} />
                <Block leftIndent>
                    <Title type={"h1"} color={"gold"} mb={40}>
                        Kontakt
                    </Title>
                    <GeneralContact />
                </Block>

                <Block radialGradient>
                    <div className={styles.row2Cols}>
                        <div>
                            <Title color={"blueDark"} type={"h3"} mb={25}>
                                OZVĚTE SE NÁM NEBO NECHTE KONTAKT A MY SE
                                OZVEME.
                            </Title>
                            <ContactForm
                                uri="kontakt"
                                contactFormThankYouContent={<ThankYouContact />}
                                contactFormCondition={
                                    "Potvrzuji, že jsem se seznámil/a se "
                                }
                                contactFormConditionLink={URLS.gdpr}
                                contactFormConditionLinkLabel={
                                    "zpracováním mých osobních údajů"
                                }
                                contactFormConditionAfter={
                                    " ze strany společnosti KAPITOL, a.s."
                                }
                                redirect={"/thank-you-contact"}
                            />
                        </div>
                        <div>
                            <CompanyHeadquater />
                        </div>
                    </div>
                </Block>
            </SiteLayout>
        );
    }
}
