import React, { Fragment } from "react";

import styles from "./headquater.module.sass";
import Title from "../Title";

const CompanyHeadquater = () => (
    <Fragment>
        <div className={styles.wrapper}>
            <Title color={"blueDark"} type={"h3"} mb={25}>
                Sídlo společnosti
            </Title>

            <p className={styles.text}>
                KAPITOL, a.s.
                <br />
                IČ: 60751070 Vlněna 526/3, Trnitá, 602 00 Brno
            </p>
            <p className={styles.small}>
                Společnost zapsaná v&nbsp;obchodním rejstříku vedeném Krajským
                soudem v&nbsp;Brně, oddíl B, vložka 1604
            </p>
        </div>
    </Fragment>
);

export default CompanyHeadquater;
