import React from "react";

import style from "./generalContact.module.sass";
import phoneLogo from "../../images/icons/phone-gold.svg";
import emailLogo from "../../images/icons/email.svg";

const GeneralContact = () => (
    <div className={style.wrapper}>
        <p className={style.item}>
            <img src={phoneLogo} alt="Telefon" />
            <span className={style.info}>
                <a className={style.info} href="tel:+420 910 902 737">
                    +420 910 902 737{" "}
                </a>
            </span>
        </p>
        <p className={style.item}>
            <img src={emailLogo} alt="E-mail" />
            <a href="mailto:info@kapitolgold.cz" className={style.link}>
                <span className={style.info}>info@kapitolgold.cz</span>
            </a>
        </p>
    </div>
);

export default GeneralContact;
